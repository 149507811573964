'use strict';

(function() {
  class ViewNoteModalCtrl {
    constructor($uibModalInstance, Notes, options) {
      this.$uibModalInstance = $uibModalInstance;
      this.Notes = Notes;
      this.options = options;
      if (this.options.noteId) {
        this.noteId = angular.copy(this.options.noteId);
      }
      this.init();
    }

    init() {
      this.Notes.get({ id: this.noteId })
        .$promise.then((response) => {
          if (response.data) {
              this.noteDetail = response.data;
          }
      })
      .catch((err) => {
        console.log(err);
      });
    }
  }
  angular.module('windmanagerApp')
    .controller('ViewNoteModalCtrl', ViewNoteModalCtrl);
})();
